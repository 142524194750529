(function ($) {

    var pageBanner = $('.js-banner-width')

    scaleBanner(pageBanner)

    $( window ).resize(function() {
        scaleBanner(pageBanner)
    })


    function scaleBanner(banner) {
        if (banner.length > 0) {
            banner.each(function() {
                var that = $(this),
                    containerWidth = that.parents('.banner-container').width(),
                    windowWidth = $(window).width(),
                    bannerContent = that.next('#js-banner-additional-content')

                if (windowWidth > 1025) {
                    that.css({
                        maxWidth: containerWidth + ((windowWidth - containerWidth) / 2)
                    })

                    if (bannerContent.length) {
                        bannerContent.css({
                            right: - (pageBanner.width() - containerWidth) + 15
                        })
                    }
                }
                else {
                    that.css({
                        maxWidth: '100%'
                    })

                    if (bannerContent.length) {
                        bannerContent.css({
                            right: 0
                        })
                    }
                }
            })
        }
    }


})(jQuery);