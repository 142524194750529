(function ($) {
   
    /**
     * inits
     */
    var menuItem = $('.main-menu__item').find('a.menu-level-0'),
        megamenu = '.megamenu'

    /**
     * show megamenu
     */
    $(menuItem).hover(function() {
        var currentMegamenu = $(this).next(megamenu)

        close_megamenu()

        if (currentMegamenu.length > 0) {
            currentMegamenu.addClass('active')
        }
        else {
            close_megamenu()
        }
    });

    $('.js-hide-megamenu').hover(function() {
        close_megamenu()
    })

    $('#main-header').mouseleave(function() {
        close_megamenu()
    })

    /**
     * close megamenu
     */
    function close_megamenu() {
        $(megamenu).each(function() {
            $(this).removeClass('active')
        })
    }
 
 })(jQuery);