(function ($) {
    
    /**
     *  init slick slider
     */

    var $slider = $('#js-about-reviews-slider');

    $slider.slick({
        dots: true,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        appendDots: $('#js-about-reviews-slider-dots'),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    });

})(jQuery);