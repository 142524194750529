(function ($) {
    //init before-after

    initBeforeAfter();
    
    /**
     *  init slick slider
     */

    var $slider = $('#js-home-slider');

    $slider.slick({
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        appendDots: $('#js-home-slider-dots'),
    });


    $slider.on('afterChange', function(){
        initBeforeAfter();
    })

})(jQuery);