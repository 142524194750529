(function ($) {
    
    /**
     *  init slick slider
     *  zakomentowano na wyraźną prośbę Candeo task w redminie 5592
     */

    // var $slider = $('#js-home-offer-slider');

    // $slider.slick({
    //     dots: true,
    //     infinite: true,
    //     slidesToShow: 3,
    //     slidesToScroll: 3,
    //     arrows: false,
    //     appendDots: $('#js-home-offer-slider-dots'),
    //     responsive: [
    //         {
    //             breakpoint: 1024,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //             }
    //         }
    //     ]
    // });

})(jQuery);