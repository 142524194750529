(function ($) {
    
    /**
     *  init slick slider
     */

    var $slider = $('#js-offer-reviews-slider');

    $slider.slick({
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        appendDots: $('#js-offer-reviews-slider-dots'),
    });

})(jQuery);