(function ($) {
    
    var $trigger = '.js-gallery-trigger',
        $galleryContent = $('#js-gallery-modal-body'),
        $modal = $('#js-gallery-modal'),
        $caption = $('#js-gallery-modal-caption'),
        $counterAll = $('#js-gallery-counter-all'),
        $counterCurrent = $('#js-gallery-counter-current')

    $('body').on('click', $trigger, function(e) {
        e.preventDefault()
        _galleryLoad($(this).attr('data-rel'), $(this).attr('data-image-index'))
    })
    
    function _galleryLoad(galleryRel, imageIndex) {
        
        //create slider
        var slider = new Promise(function(resolve, reject) {
            var sliderItems = []
            $counterAll.text($('img[data-rel="' + galleryRel + '"]').length)

            $('img[data-rel="' + galleryRel + '"]').each(function() {
                sliderItems.push($(this).attr('data-image-target'))
                $galleryContent.append('<div class="gallery-modal-item" data-caption="' + $(this).attr('data-caption') + '"><img class="gallery-modal-item__image" src="' + $(this).attr('data-image-target') + '"></div>')
            })
            resolve();
        })

        slider.then(function(){
            $modal.addClass('gallery-modal--loading').modal('show')

            setTimeout(function() {
                $galleryContent.slick({
                    dots: false,
                    infinite: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    prevArrow: '<span class="gallery-modal__arrow gallery-modal__arrow--prev"></span>',
                    nextArrow: '<span class="gallery-modal__arrow gallery-modal__arrow--next"></span>',
                })
                $galleryContent.slick('slickGoTo', imageIndex)

                $galleryContent.on('afterChange', function(event, slick, currentSlide) {
                    var caption = $('[data-slick-index="' + currentSlide + '"]').find('.gallery-modal-item').attr('data-caption')
                    if (caption) {
                        $caption.text(caption)
                    }
                    else {
                        $caption.text('')
                    }
                    $counterCurrent.text(currentSlide + 1)
                })
            }, 150)

            setTimeout(function() {
                $modal.removeClass('gallery-modal--loading')
            }, 550)
        })
    }

    $modal.on('hidden.bs.modal', function (e) {
        $galleryContent.slick('unslick').html('')
    })

})(jQuery);
    