(function ($) {

    /**
     * shrink header on scroll
     */

    var $mainHeader = $('#main-header')

    scrollInit();

    $(window).scroll(function() {
        scrollInit();
    });

    function scrollInit() {
        var scroll = $(window).scrollTop();

        if (scroll >= 20) { 
            $mainHeader.addClass('main-header--scroll');
        }
        else { 
            $mainHeader.removeClass('main-header--scroll'); 
        }
    }

})(jQuery);