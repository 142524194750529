(function ($) {
    $(window).on('scroll load', function () {
        lazyLoadImages();
    });

    $(window).on('load', function () {
        lazyLoadImages(true);
    });

    /**
     * Funkcja ładująca zdjęcia w trybie "lazy load".
     * 
     * Działa w dwóch trybach:
     * a) Tryb ładujący dane zdjęcie
     *    Aby skorzystać z tego trybu do grafiki lub div'a z obrazem tła należy:
     *    - dodać klasę 'js-lazy-image'
     *    - dodać style="background-image:url(<?= get_placeholder('thumbnail'); ?>"
     *      lub src="<?= get_placeholder('thumbnail'); ?>" (pamiętać, aby podmienić wielkość grafiki)
     *    - dodać parametr data-lazy-background="" wskazujący na finalną grafikę
     * 
     * b) Tryb ładujący inne zdjęcia po kliknięciu na zapalnik
     *    Aby skorzystać z tego trybu należy do przycisku bądź innego elementu
     *    uruchamiającego należy wykonać takie same czynności jak dla trybu ładującego
     *    zdjęcia oraz dodatkowo:
     *    - na zdjęciach do załadowania zamiast klasy 'js-lazy-image' dajemy 'js-lazy-image-load-on-fire'
     *    - na przycisku "zapalniku" dajemy klasę 'js-lazy-image-fire-loads'
     *    - na zdjęciach i zapalniku dodać taki sam parametr 'data-lazy-load-id'
     *      (jest to informacja, które zdjęcia mają odpalić się na ten konketny fire)
     *    Ten tryb usuwa z zapalnika akcję odpalenia po pierwszym uruchomieniu.
     * 
     * @version 1.0.0
     */
    function lazyLoadImages (triggerFireMode) {
        $('.js-lazy-image').each(function(index, item) {
            let $this = $(this),
                $thisParent = $this.parent(),
                type = $this.prop('tagName'),
                loadBottomOffset = -200; // Odstęp od dołu po jakim ma się załadować grafika.

            if ($this.offset().top < $(document).scrollTop() + $(window).height() - loadBottomOffset) {
                let trueSource = $this.data('lazy-background');

                if (type == 'IMG') {

                    if (trueSource !== $this.attr('src')) {
                        $this.attr('src', trueSource);
                    }
                } else if (type == 'DIV') {

                    if ('url("' + trueSource + '")' !== $this.css('background-image')) {
                        $this.css('background-image', 'url(' + trueSource + ')');
                    }
                } else {
                    console.error('Podany element dla "lazy load" nie nadaje się do załadowania!');
                }

                // Patrzymy czy parent to <picture> - jeśli tak to szukamy w nim <source> i podmieniamy 'srcset'.
                if ($thisParent.is('picture')) {
                    let $sourceTag = $thisParent.find('source');

                    $sourceTag.attr('srcset', trueSource);
                }
            }
        });

        if (triggerFireMode) {
            $('.js-lazy-image-fire-loads').on('click', function () {
                var $thisFire = $(this),
                    dataLazyLoadId = $thisFire.data('lazy-load-id');

                $('.js-lazy-image-load-on-fire').each(function(index, item) {
                    let $thisImage = $(this),
                        type = $thisImage.prop('tagName');

                    if (dataLazyLoadId == $thisImage.data('lazy-load-id')) {
                        if (type == 'IMG') {
                            let trueSource = $thisImage.data('lazy-background');

                            if (trueSource !== $thisImage.attr('src')) {
                                $thisImage.attr('src', trueSource);
                            }
                        } else if (type == 'DIV') {
                            let trueSource = $thisImage.data('lazy-background');

                            if ('url("' + trueSource + '")' !== $thisImage.css('background-image')) {
                                $thisImage.css('background-image', 'url(' + trueSource + ')');
                            }
                        } else {
                            console.error('Podany element dla "lazy load" nie nadaje się do załadowania!');
                        }
                    }
                });

                // Optymalizacja - dla następnego kliknięcia w fire nie obciążamy całego DOM'u ;>
                $thisFire.removeClass('js-lazy-image-fire-loads');
            });
        }
    }

})(jQuery);
