(function ($) {
    
    $('ul.main-menu__submenu-nav').each(function() {
        $(this).filter(function(){
            return $(this).children().length > 7
        }).addClass('main-menu__submenu-columns');
    })
    

})(jQuery);
    